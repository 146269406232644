<template>
  <div class="successful_certification">
    <main>
      <img src="@/assets/images/successful_icon.png" alt="" />
      <h2>认证成功，开始分佣推广</h2>
      <van-button
        round
        type="info"
        url="#/home"
        native-type="submit"
        color="#108BFF"
      >
        回到首页
      </van-button>
    </main>
  </div>
</template>

<script>
export default {
  name: "successful-certification",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.successful_certification {
  padding-top: 46px;
  text-align: center;
  background: #fff;
  main {
    padding: 76px 0;
    border-top: 1px solid #c9c9c9;
    img {
      margin-bottom: 20px;
    }
    h2 {
      margin-bottom: 64px;
    }
    .van-button {
      padding: 0 34px;
    }
  }
}
</style>
